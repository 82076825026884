import { useState } from 'react';
import './App.css';

type Target = {
  url: string,
  title?: string,
  element?: JSX.Element,
};

const targets: Record<string, Target> = {
  logo: {
    url: "http://192.168.178.14:32400/web/index.html#!/media/9c3d0cb9793154d4d254e0fbf3b197c0cc7dfe4f/com.plexapp.plugins.library?key=%2Fhubs&pageType=hub",
    element: (<img src="/crab.png" height={50} width={50} className="App-logo" alt="logo" />),
  },
  movies: {
    url: "http://192.168.178.14:7878/add/new",
    title: "Movies",
  },
  tv: {
    url: "http://192.168.178.14:8989/add/new",
    title: "TV",
  },
};

type MenuItem = keyof typeof targets;


function App() {
  const [target, setTargetRaw] = useState("movies" as MenuItem);
  const setTarget = (type: MenuItem) => {
    setTargetRaw(type);
  };
  return (
    <div>
      <header className="App-header">
        <div>
          <span className="logo" onClick={() => setTarget("logo")}>
            { targets.logo.element }
          </span>
          {(Object.keys(targets) as MenuItem[]).map((type) => ( type !== "logo" &&
            <span className={target === type ? "selected" : ""} onClick={() => setTarget(type)}>
              <span className="title">{targets[type].title}</span>
            </span>
          ))}
        </div>
      </header>
      <div>
        <iframe allowFullScreen={true} title="Content" style={{ width: "100%", height: "800px" }} src={(targets[target].url)}></iframe>
      </div>
    </div>
  );
}

export default App;
